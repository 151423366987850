<template>
  <modal-container
    @cancel="$emit('cancel')"
  >

    <p class="text-xl font-extrabold text-left text-primary">{{userInfo.name}}</p>
    <p class="mt-2 font-bold text-left">
      You can either update the users permissions below,
      or you can remove the user from the project complertely
    </p>
    <div class="flex justify-start w-full pt-4">
      <p class="mr-3 font-bold">Permissions: </p>
      <select
        v-model="permissions"
        class="bg-transparent outline-none text-input focus:outline-none"
      >
        <option value="basic">Basic</option>
        <option value="admin">Admin</option>
      </select>
    </div>
    <div class="flex flex-row justify-end w-full pt-7">
      <button-large
        @click="$emit('remove-user')"
        class="mr-3 w-28 phone:w-36"
        color="red"
      >
        Remove
      </button-large>
      <button-large
        @click="updatePermissions"
        class="w-28 phone:w-36"
        :disabled="!canSave"
        color="green"
      >
        Save
      </button-large>
    </div>

  </modal-container>
</template>

<script>
import ModalContainer from '@/components/shared/containers/ModalContainer.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'ModalTextInput',
  components: {
    ModalContainer,
    ButtonLarge,
  },
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
    textLeft: {
      type: String,
      required: true,
    },
    textRight: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      permissions: '',
    };
  },
  computed: {
    canSave() {
      return this.userInfo.permissions !== this.permissions;
    },
  },
  mounted() {
    this.permissions = this.userInfo.permissions;
  },
  methods: {
    updatePermissions() {
      this.$emit('update:user-info', {
        ...this.userInfo,
        permissions: this.permissions,
      });
      if (this.canSave) this.$emit('update-permission');
    },
  },
};
</script>

<style>

</style>
