<template>
    <interior-page-content-container
      nav-selection=""
      @back-click="$router.push(defaultBackRoute)"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      :modal-show="showModal"
      :loading="loading"
      :notificationMessage.sync="notificationMessage"
      :notificationTime="notificationTime"
      :notificationType="notificationType"
    >

      <template #modal>
        <walkthrough-modal
          v-if="showWalkthroughModal"
          title="Invite a User!"
          @confirm="incrementWalkthrough"
          @cancel="cancelWalkthrough"
          description="
            This is where you can invite existing KIV members to join you
            on your KIV endevours. What's the point in having a good thing
            if you can't shre it right!
          "
          textCancel='Finish'
          textConfirm='View Invite'
        />

        <modal-confirmation
          v-if="modal.deleteProject"
          title="Delete Project?"
          description="If you delete the project all of it's data will be lost!"
          text-confirm="Delete"
          text-cancel="Cancel"
          @confirm="confirmDeleteProject"
          @cancel="modal.deleteProject = false"
        />

        <modal-confirmation
          v-if="modal.leaveProject"
          title="Leave the Project?"
          description="If you leave the project you will no longer have any access to it"
          text-confirm="Leave"
          text-cancel="Cancel"
          @confirm="confirmLeaveProject"
          @cancel="modal.leaveProject = false"
        />

        <project-settings-add-users-modal
          v-if="modal.addUsers"
          text-cancel="Cancel"
          text-confirm="Invite"
          @confirm="addUsersToProject"
          @cancel="cancelAddUsersToProject"
          type="email"
        />

        <project-settings-member-edit-modal
          v-if="modal.memberSettings"
          text-left="Cancel"
          text-right="Save"
          :userInfo.sync="userToEdit"
          @update-permission="launchUserPermissionsModal"
          @remove-user="removeUserFromProject(userToEdit)"
          @cancel="modal.memberSettings = false"
        />

      </template>

      <template #title>
        Project Settings
      </template>

      <template #content>

        <div class="`
          flex flex-col items-stretch self-center h-full pt-10
          w-5/6 phone:w-3/4 md:w-2/3 lg:w-3/5 max-w-md
          mb-10 pb-10
        `">

          <!-- Image and name -->
          <div class="flex items-center flex-shrink-0 w-full mb-4">
            <circular-image-component
              :picture="picture"
              class="flex-shrink-0"
            />
            <div class="flex flex-col h-16 pl-3 text-left">
              <p class="text-2xl font-extrabold text-primary">
                {{project.name}}
              </p>
              <p class="text-sm text-content">
                *Landscape Pictures will display best
              </p>
            </div>
          </div>

          <!-- Project info -->
          <div class="w-full p-3 mb-4 rounded-lg bg-backfill">
            <text-input-component
              @save="put"
              :value.sync="project.name"
              :valid="project.name.length > 0"
              label="Project Name"
              :mode="editOrDisplayMode"
            />
            <text-input-component
              @save="put"
              :value.sync="project.address"
              :valid="true"
              label="Postal / Zip Code"
              type="postalCode"
              :mode="editOrDisplayMode"
            />
            <text-input-component
              label="Owner"
              :value.sync="project.owner.name"
              mode="display"
              :valid="true"
            />
          </div>

          <!-- Actions -->
          <div
            v-if="projectPermissions !== 'basic'"
            class="w-full p-3 mb-4 rounded-lg bg-backfill"
          >
            <button-inline
              v-if="projectPermissions === 'owner'"
              @click="showAddUsersModal"
              label="Members"
              value="Add"
              mode="edit"
              :disabled="false"
              :pulse="pulseAddUser"
            />
            <button-inline
              @click="takePicture"
              label="Picture"
              value="Update"
              mode="edit"
              :disabled="false"
            />
            <input
              @change="newPicture = $event.target.files[0]"
              v-show='false' type="file" accept="image/*"
            />
          </div>

          <!-- Admins -->
          <div v-if="project.admins.length > 0" class="w-full mb-4 admins">
            <p class="ml-5 text-xl font-extrabold text-left text-content">Admins</p>
            <div class="w-full p-3 rounded-lg bg-backfill ">
              <button-inline
                v-for="(admin, index) in project.admins"
                :key="'admin-index-'+index"
                @click="editUserPermissions(admin)"
                :label="admin.name"
                value="Edit"
                :disabled="projectPermissions !== 'owner'"
                :mode="projectPermissions === 'owner' ? 'edit' : 'display'"
              />
            </div>
          </div>

          <!-- Users -->
          <div v-if="project.users.length > 0" class="w-full mb-4 users">
            <p class="ml-5 text-xl font-extrabold text-left text-content">Basic Members</p>
            <div class="w-full p-3 rounded-lg bg-backfill ">
              <button-inline
                v-for="(user, index) in project.users"
                :key="'admin-index-'+index"
                @click="editUserPermissions(user)"
                :label="user.name"
                value="Edit"
                :disabled="projectPermissions !== 'owner'"
                :mode="projectPermissions === 'owner' ? 'edit' : 'display'"
              />
            </div>
          </div>

          <!-- Rooms -->
          <div v-if="project.rooms && project.rooms.length > 0" class="w-full mb-4 rooms">
            <p class="ml-5 text-xl font-extrabold text-left text-content">Rooms</p>
            <div class="flex flex-col w-full p-3 rounded-lg bg-backfill ">
              <div
                v-for="(room, index) in project.rooms"
                :key="room.name"
                class="w-full"
              >
                <radio-button
                  @click="roomClicked(room.id)"
                  :label="room.name"
                  :checked="roomsToDelete.includes(room.id)"
                  :canDelete="editRooms"
                  color="red"
                  class="text-white"
                />
                <div v-if="index !== project.rooms.length -1" class="border border-contrast">
                </div>
              </div>

              <div
                v-if="projectPermissions !== 'basic'"
                class="flex justify-around w-full"
              >
                <button-small
                  @click="toggleRoomEdit"
                  color="secondary"
                  class="self-center"
                >
                  {{editRooms ? "Cancel" : "Edit" }}
                </button-small>

                <button-small
                  v-if="editRooms"
                  @click="deleteRooms"
                  color="red"
                  class="self-center"
                >
                  Delete
                </button-small>
              </div>

            </div>
          </div>

          <button-large
            v-if="projectPermissions === 'owner'"
            @click="modal.deleteProject = true"
            color="red"
            class="self-center"
          >
            Delete Project
          </button-large>

          <button-large
            v-if="projectPermissions !== 'owner'"
            @click="modal.leaveProject = true"
            color="red"
            class="self-center"
          >
            Leave Project
          </button-large>
          <div class="h-10 opacity-0">space...</div>
        </div>
      </template>
    </interior-page-content-container>
</template>

<script>

import { mapProjectFields } from '@/store/mappers';
import {
  USER_WALKTHROUGH_DISPATCH,
  PROJECT_GET, PROJECT_PUT, PROJECT_DELETE, PROJECT_ADD_USER,
  PROJECT_REMOVE_USER, PROJECT_UPDATE_USER_PERMISSIONS,
  ROOM_DELETE,
} from '@/store/actions';
import { USER_WALKTHROUGH_STATE, USER_DETAILS, PROJECT_PERMISSIONS_CURRENT } from '@/store/getters';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import ButtonSmall from '@/components/shared/Buttons/ButtonSmall.vue';
import ProjectSettingsMemberEditModal from '@/components/projects/ProjectSettingsMemberEditModal.vue';
import ProjectSettingsAddUsersModal from '@/components/projects/ProjectSettingsAddUsersModal.vue';
import CircularImageComponent from '@/components/shared/general/CircularImageComponent.vue';
import TextInputComponent from '@/components/shared/inputs/TextInputComponent.vue';
import ButtonInline from '@/components/shared/Buttons/ButtonInline.vue';
import RadioButton from '@/components/shared/general/RatioButton.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';
import ModalConfirmation from '@/components/shared/inputs/ModalConfirmation.vue';
import WalkthroughModal from '../../components/walkthrough/WalkthroughModal.vue';

export default {
  name: 'ProjectSettings',
  components: {
    ProjectSettingsAddUsersModal,
    ProjectSettingsMemberEditModal,
    ModalConfirmation,
    WalkthroughModal,
    InteriorPageContentContainer,
    CircularImageComponent,
    TextInputComponent,
    ButtonInline,
    RadioButton,
    ButtonSmall,
    ButtonLarge,
  },
  data() {
    return {
      toDelete: false,
      roomsToDelete: [],
      projectValidity: {
        nameValid: true,
        addressValid: true,
      },
      modal: {
        addUsers: false,
        memberSettings: false,
        leaveProject: false,
        deleteProject: false,
      },
      editRooms: false,
      loading: true,
      newPicture: null,
      addUsers: false,
      userToEdit: {
        name: '',
        email: '',
        id: 0,
      },
      notificationType: 'success',
      notificationMessage: '',
      notificationTime: 5000,
    };
  },
  computed: {
    ...mapProjectFields(['project']),
    projectPermissions() {
      return this.$store.getters[PROJECT_PERMISSIONS_CURRENT];
    },
    valid() {
      return this.projectValidity.nameValid && this.projectValidity.addressValid;
    },
    picture() {
      if (this.newPicture) return URL.createObjectURL(this.newPicture);
      return this.project.picture.url;
    },
    defaultBackRoute() {
      return {
        name: 'Project',
        params: {
          project_id: this.$route.params.project_id,
          tab: 'Declutter',
        },
      };
    },
    showModal() {
      return this.modal.deleteProject
      || this.modal.addUsers || this.modal.memberSettings || this.modal.leaveProject
      || this.showWalkthroughModal;
    },
    editOrDisplayMode() {
      return this.projectPermissions !== 'basic' ? 'edit' : 'display';
    },
    showWalkthroughModal() {
      return this.$store.getters[USER_WALKTHROUGH_STATE] === 12;
    },
    pulseAddUser() {
      return this.$store.getters[USER_WALKTHROUGH_STATE] === 13;
    },
    inviteUserWalkthrough() {
      return this.$store.getters[USER_WALKTHROUGH_STATE] === 14;
    },
  },
  created() {
    this.loading = this.project === {} ? true : this.project.id !== this.$route.params.project_id;
    this.loadProject();
  },
  watch: {
    project: {
      handler(newVal, oldVal) {
        if (oldVal.name !== null) {
          this.projectValidity.nameValid = newVal.name.length > 0;
          this.projectValidity.addressValid = newVal.address.length > 0;
        }
      },
      deep: true,
    },
    newPicture() { this.put(); },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters[USER_WALKTHROUGH_STATE] < 100) {
      if (to.name === 'Dashboard') {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH);
      } else if (this.$store.getters[USER_WALKTHROUGH_STATE] !== 0) {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
      }
    }
    next();
  },
  methods: {
    incrementWalkthrough() {
      this.$store.dispatch(USER_WALKTHROUGH_DISPATCH);
    },
    cancelWalkthrough() {
      this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
    },
    loadProject() {
      this.$store.dispatch(PROJECT_GET, this.$route.params.project_id)
        .catch(() => this.$router.push({ name: 'PageNotFound' }))
        .finally(() => { this.loading = false; });
    },
    put() {
      const putProject = {
        name: this.project.name,
        address: this.project.address,
      };
      if (this.newPicture) putProject.picture = this.newPicture;
      return this.$store.dispatch(PROJECT_PUT, {
        projectId: this.$route.params.project_id,
        project: putProject,
      })
        .catch(() => {
          console.log('The project was not updated');
        });
    },
    takePicture() {
      document.querySelector('input[type="file"]').click();
    },
    showAddUsersModal() {
      this.modal.addUsers = true;
      if (this.pulseAddUser) {
        this.incrementWalkthrough();
      }
    },
    doneInvitedUserForWalkthrough() {
      if (this.inviteUserWalkthrough) {
        this.$router.push({ name: 'Dashboard' });
      }
    },
    cancelAddUsersToProject() {
      this.modal.addUsers = false;
      this.doneInvitedUserForWalkthrough();
    },
    async addUsersToProject(data) {
      const promises = [];
      const errors = [];

      // Prevents user from adding someone who is already part of the project
      let emailAlreadyAdded = false;
      data.emails.forEach((_email) => {
        const admin = this.project.admins.filter((_admin) => _admin.email === _email);
        const user = this.project.users.filter((_user) => _user.email === _email);
        if (admin.length > 0 && user.length > 0) emailAlreadyAdded = true;
        if (this.project.owner.email === _email) emailAlreadyAdded = true;
      });
      if (emailAlreadyAdded) {
        this.modal.addUsers = false;
        this.notificationMessage = 'Some of the users you invited are already Project Members. Please exclude them and try again.';
        this.notificationType = 'alert';
        this.notificationTime = 12000;
        return;
      }

      data.emails.forEach((email) => {
        promises.push(
          this.$store.dispatch(PROJECT_ADD_USER, {
            email,
            permissions: data.permissions,
            projectId: this.$route.params.project_id,
          })
            .catch((error) => errors.push({
              message: error.response.data,
              email,
            })),
        );
      });
      this.modal.addUsers = false;

      this.loading = true;
      Promise.all(promises)
        .finally(() => {
          if (errors.length === 0) {
            this.notificationMessage = 'Your project has been shared!';
            this.notificationType = 'success';
            this.notificationTime = 5000;
          } else {
            let emailErrors = errors.reduce((pastVal, curVal) => `${pastVal} ${curVal.email}`, '').trim();
            emailErrors = emailErrors.split(' ').join(', ');
            const message = `This project cannot be shared with the following email${errors.length > 1 ? 's' : ''} as 
              ${errors.length > 1 ? 'they are' : 'it is'}
              not registered with Kept in View: ${emailErrors}`;
            this.notificationMessage = message;
            this.notificationType = 'alert';
            this.notificationTime = 7000;
          }
          this.loadProject();
          this.loading = false;

          this.doneInvitedUserForWalkthrough();
        });
    },
    launchUserPermissionsModal() {
      this.modal.memberSettings = false;
      this.loading = true;
      this.$store.dispatch(PROJECT_UPDATE_USER_PERMISSIONS, {
        projectId: this.$route.params.project_id,
        userId: this.userToEdit.id,
        permissions: this.userToEdit.permissions,
      })
        .finally(() => {
          this.loadProject();
          this.loading = false;
        });
    },
    editUserPermissions(user) {
      this.userToEdit = { ...user };
      if (this.project.admins.includes(user)) this.userToEdit.permissions = 'admin';
      if (this.project.users.includes(user)) this.userToEdit.permissions = 'basic';
      this.modal.memberSettings = true;
    },
    removeUserFromProject(user) {
      this.modal.memberSettings = false;
      this.$store.dispatch(PROJECT_REMOVE_USER, {
        userId: user.id,
        projectId: this.$route.params.project_id,
      })
        .finally(() => this.loadProject());
    },
    createRoom() {
      this.$router.push({
        name: 'RoomCreate',
        params: {
          project_id: this.$route.params.project_id,
        },
      });
    },
    toggleRoomEdit() {
      this.editRooms = !this.editRooms;
      this.roomsToDelete = [];
    },
    roomClicked(index) {
      if (this.roomsToDelete.includes(index)) {
        this.roomsToDelete = this.roomsToDelete.filter((roomIndex) => roomIndex !== index);
      } else this.roomsToDelete.push(index);
    },
    async deleteRooms() {
      const { rooms } = this.project;
      const promises = [];
      this.roomsToDelete.forEach((roomId) => {
        promises.push(
          this.$store.dispatch(ROOM_DELETE, {
            projectId: this.$route.params.project_id,
            roomId,
          }),
        );
      });
      await Promise.all(promises);
      this.project.rooms = rooms.filter((room) => !this.roomsToDelete.includes(room.id));
      this.roomsToDelete = [];
    },
    confirmLeaveProject() {
      this.modal.leaveProject = false;
      this.$store.dispatch(PROJECT_REMOVE_USER, {
        userId: this.$store.getters[USER_DETAILS].id,
        projectId: this.$route.params.project_id,
      })
        .finally(() => this.$router.push({ name: 'Projects' }));
    },
    confirmDeleteProject() {
      this.actionComplete = true;
      this.$store.dispatch(PROJECT_DELETE, this.$route.params.project_id)
        .then(() => {
          this.$router.push({ name: 'Projects' });
        });
    },

  },

};
</script>
