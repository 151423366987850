<template>
  <modal-container
    @cancel="$emit('cancel')"
  >
    <p class="text-xl font-bold text-left text-primary">
      Add Users to the Project
    </p>
    <p class="pt-2 font-bold text-left">
      Enter multiple user's email addresss and they will be
      invited to collaborate with you on this project
    </p>
    <div class="flex justify-start w-full pt-4 font-bold">
      <p class="mr-3">Permissions: </p>
      <select
        v-model="permissions"
        class="bg-transparent outline-none text-input focus:outline-none"
      >
        <option value="basic">Basic</option>
        <option value="admin">Admin</option>
      </select>
    </div>
    <div class="flex flex-wrap w-full px-4 bg-backfill rounded-lg">
      <tokenized-input
        type="email"
        :resultingTokens.sync="emails"
      />
    </div>
    <div class="flex flex-row justify-end w-full pt-4">
      <button-large
        @click="$emit('cancel')"
        class="mr-3 w-28 phone:w-36"
        color="secondary"
      >
        {{textCancel}}
      </button-large>
      <button-large
        @click="addUsers"
        :disabled="emails.length === 0"
        color="green"
        class="w-28 phone:w-36"
      >
        {{textConfirm}}
      </button-large>
    </div>

  </modal-container>
</template>

<script>
import TokenizedInput from '@/components/shared/inputs/TokenizedInput.vue';
import ModalContainer from '@/components/shared/containers/ModalContainer.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'ProjectSettingsAddUsersModal',
  components: {
    ModalContainer,
    TokenizedInput,
    ButtonLarge,
  },
  props: {
    textCancel: {
      type: String,
      required: true,
    },
    textConfirm: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
      validate: (val) => ['email', 'text'].includes(val),
    },
  },
  data() {
    return {
      input: '',
      emails: [],
      permissions: 'basic',
    };
  },
  methods: {
    addUsers() {
      this.$emit(
        'confirm',
        {
          emails: this.emails,
          permissions: this.permissions,
        },
      );
    },
  },
};
</script>

<style>

</style>
